// TODO CONFIG 
const config = {
  name: 'el Quizz Dev',
  firebase_config: {
    apiKey: "AIzaSyB9vXwS6-cGcqBmrE_VDJBfaMoxDRF_cAY",
    authDomain: "el-quiz-dev.firebaseapp.com",
    databaseURL: "https://el-quiz-dev.firebaseio.com/",
    projectId: "el-quiz-dev",
    storageBucket: "el-quiz-dev.appspot.com",
    messagingSenderId: "541924199882"
  },
  dev: true,
  cloudHttps: 'https://europe-west1-el-quiz-dev.cloudfunctions.net/',
  backgroundColor: '#484848',
  templateQuestionCsv: 'https://firebasestorage.googleapis.com/v0/b/quidol-146ff.appspot.com/o/backOfficeUploads%2FquizzesCsv%2FtemplateQuestions.csv?alt=media&token=23ac0289-d9c9-4960-aa11-eb7e67221387',
  templateQuizzesCsv: 'https://firebasestorage.googleapis.com/v0/b/quidol-146ff.appspot.com/o/backOfficeUploads%2FquizzesCsv%2FtemplateQuizzes.csv?alt=media&token=50f6bdec-9ccb-4eab-a273-b2b962f8aa2c',
  templateGoCsv: 'https://firebasestorage.googleapis.com/v0/b/el-quiz-dev.appspot.com/o/backOfficeUploads%2FgoFun%2Ftemplate.csv?alt=media&token=4552f2d7-7c11-4b72-8111-51bd34a1a276',
  firebaseLoad: () => require('./firebase'),
  version: '1.0.0',
  author: 'EL QUIZ <el-quiz-dev>',
  defaultLiveVideoUri: 'default',
  defaultBackOffice: false,
  enableAnswers: true,
  enableMessageHistory: true,
  enableMessages: true,
  enableUserDelete: true,
  enableUserDetails: true,
  numberJokersNext: 2,
  showQuizType: true,
  defaultNumberOfQuestions: 10,
  paymentMethod: { iban: true, paypal: true },
  isSurveyEnabled: true,
  isGetRandomViewer: true,
  fileUpload: { enable: true, type: 'csv' },
  achievementsServer: 'https://achievement-dev.elquizz.com/',
  goServer: 'https://go-rest-dev.elquizz.com/',
  userServer: 'https://user-rest-dev.elquizz.com/',
  quizzesServer: "https://quizzes-dev.elquizz.com/",
  storageServer: "https://storage-dev.elquizz.com/",
  millisecondsBonusQuestionMusic: 8000,
  socketTVEndPoint: 'wss://tv-api-dev.elquizz.com',
  liveControlEndPoint: 'wss://live-control-dev.elquizz.com:3000', 
  defaultQuestionDuration: 12000,
};

module.exports = config;
